import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { AnimationOnScroll } from 'react-animation-on-scroll';

const Header = styled.header`
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
    min-height: 80vh; /* Zapewnia pełne tło w mobile */
    overflow: hidden;
    background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.3));

    ${({ theme }) => theme.media.tablet} {
        min-height: 90vh;
    }

    ${({ theme }) => theme.media.desktop} {
        min-height: 100vh;
    }
`;

const ImageWrapper = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover; /* Kluczowe: obrazek teraz wypełni całe tło */
    }
`;


const Content = styled.div`
     display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: white;
    padding: 20px;
    width: 90%;
    max-width: 1000px;

    h1 {
        font-family: "Righteous", cursive;
        font-size: 2rem;
        font-weight: 700;
        text-shadow: 2px 2px 6px rgba(0, 0, 0, 0.6);
        margin-bottom: 10px;

        ${({ theme }) => theme.media.tablet} {
            font-size: 4.5rem;
        }
    }

    h2 {
        font-size: 1.2rem;
        font-weight: 300;
        text-shadow: 2px 2px 6px rgba(0, 0, 0, 0.6);
        margin-bottom: 15px;

        ${({ theme }) => theme.media.tablet} {
            font-size: 1.6rem;
        }
    }
`;

const ButtonHero = styled(Link)`
    display: inline-block;
    font-size: 1rem;
    font-weight: bold;
    color: white;
    background-color: #2c5b3b;
    padding: 12px 25px;
    text-transform: uppercase;
    text-decoration: none;
    border-radius: 5px;
    transition: all 0.3s ease-in-out;
    margin-top: 20px;

    &:hover {
        background-color: #B68A65;
        box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.4);
    }

    ${({ theme }) => theme.media.tablet} {
        font-size: 1.2rem;
        padding: 15px 30px;
    }
`;

const IntroSection = styled.section`
    text-align: center;
    margin: 50px auto;
    padding: 40px 20px;
    max-width: 1000px;
    line-height: 1.5;
    font-size: 1.1em;
    font-weight: 400;
    color: #2c3e50;
    background: white;
    border-radius: 8px;

    h1 {
        font-size: 1.8em;
        color: #2c5b3b;
        font-weight: bold;
        text-transform: uppercase;
        margin-bottom: 10px;
    }

    h2 {
        font-size: 1.2em;
        font-weight: 400;
        margin: 10px 0;
        color: #1d3b25;
    }

    p {
        font-size: 1em;
        margin-top: 10px;
        color: #444;
    }

    ${({ theme }) => theme.media.tablet} {
        max-width: 90%;
        padding: 50px 30px;
        h1 {
            font-size: 2.2em;
        }
        h2 {
            font-size: 1.5em;
        }
        p {
            font-size: 1.2em;
        }
    }
`;

const ButtonCTA = styled(Link)`
    display: inline-block;
    font-size: 1.1rem;
    font-weight: bold;
    color: white;
    background-color: #2c5b3b;
    padding: 15px 30px;
    text-transform: uppercase;
    text-decoration: none;
    border-radius: 5px;
    transition: all 0.3s ease-in-out;
    margin-top: 20px;

    &:hover {
        background-color: #B68A65;
        box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.4);
    }

    ${({ theme }) => theme.media.tablet} {
        font-size: 1.2rem;
        padding: 18px 35px;
    }
`;

const Hero = () => {
    return (
        <>
            <Header>
                <ImageWrapper>
                <StaticImage
                    src="../images/hero-main.jpg"
                    alt="Ultra Race Dolina Bugu - ultramaraton rowerowy"
                    placeholder="blurred"
                    layout="constrained" /* Zmiana na constrained poprawia dopasowanie */
                    width={1600} /* Opcjonalnie: szerokość */
                    height={900} /* Opcjonalnie: wysokość */
                    style={{ width: "100%", height: "100%" }} /* Gwarantuje pełne tło */
                />
                </ImageWrapper>
                <Content>
                    <AnimationOnScroll animateIn="animate__fadeInUp" duration={1.5} animateOnce={true}>
                        <h1>SPRAWDŹ SIĘ NA JEDNEJ Z TRZECH TRAS</h1>
                        <h2>140 km | 290 km | 550 km – wybierz dystans i przeżyj przygodę!</h2>
                        <h3>📅 05-07.09.2025</h3>
                        <h3>Zapisy startują 22.02.2025 o godz. 20:00</h3>
                        <ButtonHero to="https://b4sportonline.pl/ultra_race/">Dołącz do wyścigu!</ButtonHero>
                    </AnimationOnScroll>
                </Content>
            </Header>
            
            <IntroSection>
                <h1>🚴‍♂️ ULTRAMARATON ROWEROWY – PRZYGODA, KTÓRA CIĘ WCIĄGNIE</h1>
                <h2>Trzy dystanse: 140 km | 290 km | 550 km</h2>
                <p>Ultra Race Dolina Bugu to wyścig dla tych, którzy kochają wyzwania i jazdę w otoczeniu natury.</p>
                <p>Malownicze trasy poprowadzą Cię przez szutrowe i leśne drogi, wzdłuż jednej z najdzikszych rzek w Polsce.</p>
                <p>🚀 Zapisy startują <strong>22.02.2025 o godz. 20:00</strong></p>
                <ButtonCTA to="https://b4sportonline.pl/ultra_race/">Zarejestruj się teraz!</ButtonCTA>
            </IntroSection>
        </>
    );
}

export default Hero;
